html {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  font-family: 'Gotham', 'HGGothicssi';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Gotham';
}

#root {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@keyframes animate-loading-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  85% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50vh);
    opacity: 0;
  }
}

.top-loading-animation {
  animation: animate-loading-top 3s ease-in-out;
}

@keyframes animate-loading-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  85% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50vh);
    opacity: 0;
  }
}

.bottom-loading-animation {
  animation: animate-loading-bottom 3s ease-in-out;
}

@keyframes animate-loading-logo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.logo-loading-animation {
  animation: animate-loading-logo 3s ease;
}

@keyframes animate-loading-divider {
  0% {
    transform: translateX(-100vw);
  }
  50% {
    transform: translateX(0);
  }
  85% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50vh);
    opacity: 0;
  }
}

.divider-loading-animation {
  animation: animate-loading-divider 3s ease-in-out;
}

.logo-for-unloaded {
  animation: animate-loading-logo 2.5s ease;
  animation-iteration-count: infinite;
}

@keyframes top-close-page {
  0% {
    transform: translateY(-50vh);
  }
  100% {
    transform: translateY(0);
  }
}

.top-closing-animation {
  animation: top-close-page 750ms ease;
}

@keyframes bottom-close-page {
  0% {
    transform: translateY(50vh);
  }
  100% {
    transform: translateY(0);
  }
}

.bottom-closing-animation {
  animation: bottom-close-page 750ms ease;
}

@keyframes move-background {
  0% {
    max-width: 100vw;
    overflow: hidden;
    transform: translate(0, 2rem);
  }
  100% {
    max-width: 100vw;
    overflow: hidden;
    transform: translate(-52.5%, 2rem);
  }
}

.main-intro-bg-animation {
  max-width: 100vw;
  overflow: hidden;
  animation: move-background 1750ms ease-out;
}

@font-face {
  font-family: 'HGGothicssi';
  src: url('./HGGothicssi-Black/HGGothicssi-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: 'HGGothicssi';
  src: url('./HGGothicssi-Bold/HGGothicssi-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: 'HGGothicssi';
  src: url('./HGGothicssi-Medium/HGGothicssi-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: 'HGGothicssi';
  src: url('./HGGothicssi-Light/HGGothicssi-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: 'HGGothicssi';
  src: url('./HGGothicssi-XLight/HGGothicssi-XLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: 'HGGothicssi';
  src: url('./HGGothicssi-Thin/HGGothicssi-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Black/Gotham-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Bold/Gotham-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Book/Gotham-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Light/Gotham-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Medium/Gotham-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Thin/Gotham-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Ultra/Gotham-Ultra.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-XLight/Gotham-XLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  unicode-range: U+00-7F;
}
